<template>
  <v-app>
    <v-main>
      <Notification />
      <v-fade-transition mode="out-in">
        <router-view />
      </v-fade-transition>
    </v-main>
  </v-app>
</template>

<script>
export default {
  components: {
    Notification: () => import('@/components/common/Notification.vue'),
  },
  methods: {
  },
};
</script>
<style>
</style>
